$focusBorderColour : #5897FB;

input:focus,
textarea:focus,
select:focus {
    border-color: $focusBorderColour;
}

input:disabled,
textarea:disabled,
select:disabled {
    background: $highlight;
}

option {
    margin-bottom: 5px;
}

.c-field-group {
    margin-bottom: 25px;
}
.c-field-group__container--has-scroll {
    border: 1px solid $highlight--darker;
    background: #fff;
    padding: 5px;
    width: 99.5%; /* useful when adding borders */
    height: 200px;
    overflow-y: scroll;
}
.c-field {
    margin-bottom: 15px;
    padding: 0;
    position: relative;
}
.c-field__label {
    margin-bottom: 2px;
    padding: 0;
    display: block;
}
.c-field__input-container {
    position: relative;
}
.c-field__input {
    margin: 0;
    padding: 0;
    position: relative;
}

.c-field__input--text,
.c-field__input--select,
.c-field__input--textarea,
.c-field__input--date,
.c-field__input--file {
    background: #fff;
    padding: 8px;
    border: 1px solid $highlight--darker;
    border-radius: 3px; 
    width: 100%;
    color: #333;
    font: inherit;
    transition: 0.1s;  
}
.c-field__input--select {
    height: 40px;
}
.c-field__input--multiselect {
    min-height: 120px;
}
.c-field__input--textarea {
    min-height: 150px;
}
.c-field__input--file {
    font-size: 0.85em;
}
.c-field--checkbox,
.c-field--radio {
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
}
.c-field__input--date {
    padding-right: 32px;
} 

.c-field__icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
    color: #686868; 
    pointer-events: none;
}
.c-field__icon--is-clickable {
    pointer-events: auto;
}
button.c-field__icon {
    margin: 0!important;
    padding: 0!important;
    background: 0 0!important;
    border: 0!important;
    display: inline-block!important;
    cursor: pointer!important;
    font: inherit!important;
}

/* remove "X" IE puts on inputs */
.c-field__input--text::-ms-clear {
    display: none;
}

.c-field--checkbox .c-field__input-container,
.c-field--radio .c-field__input-container {
    float: left;
    width: auto;
    line-height: 1;
}
.c-field--checkbox .c-field__label-container,
.c-field--radio .c-field__label-container {
    margin-left: 20px;
    position: relative;
    top: -1px;
}

.c-field__input--checkbox:hover,
.c-field__input--radio:hover,
.c-field--checkbox .c-field__label:hover,
.c-field--radio .c-field__label:hover {
    cursor: pointer;
}

.c-field--is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5
}

.c-field__message {
    padding: 0;
    margin: 5px 0 0 0;
    font-size: 0.9em;
}
.c-field__message--error {
    color: $colourError;
}
.c-field__message--warning {
    color: $colourWarning;
}
.c-field__message--success {
    color: $colourSuccess;
}

.c-field--has-error .c-field__input--text,
.c-field--has-error .c-field__input--select,
.c-field--has-error .c-field__input--textarea,
.c-field--has-error .c-field__input--date,
.c-field--has-error .c-field__input--file {
    border-color: $colourError;
}

.c-field--has-error .c-field-group__container,
.c-field--checkbox.c-field--has-error {
    padding: 5px;
    border: 1px solid $colourError;
    border-radius: 3px;
}
/* Search field */
.c-field--has-error ul.tagit {
    border-color: $colourError;
}

$borderColourValid : #8CC573;

.c-field--is-valid .c-field__input--text,
.c-field--is-valid .c-field__input--select,
.c-field--is-valid .c-field__input--textarea,
.c-field--is-valid .c-field__input--date,
.c-field--is-valid .c-field__input--file {
    border-color: $borderColourValid;
}

.c-field--is-valid .c-field-group__container {
    padding: 5px;
    border: 1px solid $borderColourValid;
    border-radius: 3px;
}

$tooltipColour : #E7F0FF;

.c-field__tooltip {
    padding: 10px;
    background: $tooltipColour;
    position: relative;
    border-radius: 3px;
    max-width: 320px
}
.c-field__tooltip:before {
    content: " ";
    position: absolute;
    top: -10px;
    left: 0;
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-bottom: 20px solid $tooltipColour;
    border-left: 20px solid transparent;
}
.js .c-field__tooltip {
    display: none;
}
.js .c-field__tooltip--is-showing {
    display: block;

    @include mq($from: lap) {
        position: absolute;
        right: 0;
        bottom: 100%;
        margin-bottom: 2px;

        &:before {
            top: auto;
            bottom: -10px;
            border-bottom: 0;
            border-top: 20px solid $tooltipColour;
        }
    }
}

.c-field__indicator {
    margin-left: 3px;
}

.c-field__indicator--is-required {
    color: $colourError;
    font-size: 8px;

    & i:after {
        top: -1px;
    }
}
.c-field__indicator--is-optional {
    font-size: 0.85em;
    font-style: italic;
}

.c-field--highlight {
    padding: 5px;
    background: #e6e6e6;
}

/* Select2 plugin custom styles */

/* Adding this here so we can have the toolkit.css after the style2.css */
.select2-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.select2-container {
    .select2-selection--single {
        height: 40px;
    }
    .select2-selection--multiple {
        min-height: 40px;
    }
    .select2-dropdown {
        border-color: $highlight--darker;
    }
    .select2-search--inline .select2-search__field {
        margin-top: 9px;
    }
} 

.select2-container--default {

    .select2-selection--single {
        border-color: $highlight--darker;
        border-radius: 3px;

        .select2-selection__rendered {
            color: #333;
            line-height: 40px;
        }
        .select2-selection__arrow {
            height: 38px;
        }
    }

    .select2-selection--multiple {
        border-color: $highlight--darker;
        border-radius: 3px;

        .select2-selection__choice {
            background: $highlight;
            padding: 2px 5px;
            border-radius: 3px;
        }
        
        .select2-selection__choice__remove {
            float: right;
            margin-left: 4px;
            margin-right: 0;
            padding: 0 0 0 5px;
            border-left: 1px solid #aaa;
        }

    }

    &.select2-container--focus {
        .select2-selection--single,
        .select2-selection--multiple {
            border-color: $focusBorderColour;
            outline: 0;            
        }
    }

    .select2-search--dropdown {
        .select2-search__field {
          border-color: $highlight--darker;
          &:focus {
            outline: 0;
            border-color: $focusBorderColour;
          }
        }
    }
}

.c-field--is-valid .select2-container--default .select2-selection--single,
.c-field--is-valid .select2-container--default .select2-selection--multiple {
    border-color: $borderColourValid;
}
.c-field--has-error .select2-container--default .select2-selection--single,
.c-field--has-error .select2-container--default .select2-selection--multiple {
    border-color: $colourError;
}

/* Indentation in selects */
/* The option[data-indent] selector is for when there is no JS or select2 fails */

$indent-levels: (0,1,2,3,4,5,6,7,8,9,10);

@mixin set-indentation($level) {
    .h-select-indent-#{$level},
    option[data-indent='#{$level}'] {
        padding-left: calc(10px * #{$level});
    }
}

@each $level in $indent-levels {
    @include set-indentation($level);
}